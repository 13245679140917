<template lang="pug">
    .modal_container.modal_container--testimonial(v-if='content_asset' :class="{freestanding: freestanding}")
      .modal_header
        div
          strong Optimized platform settings:
          span.preset-options
            b-button(size="sm" @click="liClick")
              img(src='./graphics/logos/linkedin-logo.svg')
            b-button(size="sm" @click="igClick")
              img(src='./graphics/logos/instagram-logo.svg')
            b-button(size="sm" @click="twClick")
              img(src='./graphics/logos/twitter-logo.svg')
            b-button(size="sm" @click="fbClick")
              img(src='./graphics/logos/facebook-logo.svg')
            b-button(size="sm" @click="ppClick")
              img(src='./graphics/logos/powerpoint-logo.svg')

        .closer(@click='$modal.hide("share_asset_modal")' v-if='!freestanding')
          TimesIcon
      .modal_body
        .share-options.mt-3.container
          .row
            section.share-option.col-sm
              h5.font-weight-bold.share-option__title Size
              b-form-group()
                //- disble flex when PDF is selected
                b-form-radio(v-model="size" name="size-radios" :value="null" :disabled="filetype === 'pdf'") Flexible
                b-form-radio(v-model="size" name="size-radios" value="wide") Wide (16:9)
                b-form-radio(v-model="size" name="size-radios" value="square") Square (1:1)
            section.share-option.col-sm
              h5.font-weight-bold.share-option__title File type
              b-form-group(:description="showSlideNudge ? 'Contact us to download PowerPoint slides' : null")
                b-form-radio(v-model="filetype" name="filetype-radios" value="png") PNG
                //- disble PDF flex is selected
                b-form-radio(v-model="filetype" name="filetype-radios" value="pdf" :disabled="!size") PDF
                b-form-radio(v-if='showSlideOptions' v-model="filetype" name="filetype-radios" value="pptx" :disabled="size") PPTX (PowerPoint)
                //- This is here so end users can discover the feature and then set it up.
                b-form-radio(v-if='showSlideNudge' :disabled="true") PPTX (PowerPoint)
          .row(v-if='filetype !== "pptx"')
            section.share-option.col-sm
              h5.font-weight-bold.share-option__title Theme
              b-form-group()
                span(v-if="fetching_themes")
                  | Loading&hellip;
                b-form-select(
                  v-model="style"
                  :options="style_options"
                  v-else
                )


        .download.mt-1
          b-button(size="lg" variant="primary" block disabled v-if="download_in_progress") Generating&hellip;
          b-button(size="lg" variant="primary" block @click="onDownload" v-if="!download_in_progress") Download

        .preview.mt-3(v-if='filetype !== "pptx"')
          AssetPresenter(
            :content_asset="content_asset"
            :design="style"
            :shape="size"
            :account="content_asset.account"
            :theme="active_theme"
          )
      .modal_footer_new(v-if='content_asset.public')
        CopyShareLink(:ueId='content_asset.identifier' class='mr-5' @copied='trackCopy')
        CopyEmbedCode(:snippet='embed_snippet')

</template>
<script lang="ts">
import TimesIcon from './graphics/TimesIcon.vue'
import CopyEmbedCode from '@components/CopyEmbedCode.vue'
import CopyShareLink from '@components/CopyShareLink.vue'
import DownloadIcon from './graphics/DownloadIcon.vue'
import AssetPresenter from '../survey/components/AssetPresenter.vue'
import { renderAsset } from '../app/api.js'
import { pendoTrack } from '@lib/pendo'
import { amplitudeTrack } from '@lib/amplitude'
import { getTestimonialThemesMap } from '@app/api.js'
import ahoy from 'ahoy.js'
import { assetEventData } from '@lib/tracking'

export default {
  components: { TimesIcon, DownloadIcon, AssetPresenter, CopyEmbedCode, CopyShareLink },
  props: ['content_asset', 'freestanding', 'custom_themes'],
  data() {
    return {
      size: null,
      filetype: 'png',
      style: 'basic',
      download_in_progress: false,
      testimonial_themes: null,
      fetching_themes: true,
      account: null,
    }
  },
  computed: {
    active_theme() {
      const current_style = this.style
      if (!current_style.match(/custom/)) {
        return null
      }
      const active_id = this.active_theme_id
      return this.testimonial_themes[active_id].data
    },
    active_theme_id() {
      if (!this.style.match(/custom/)) {
        return null
      }
      return parseInt(this.style.split(':')[1])
    },
    download_options() {
      return {
        shape: this.size,
        filetype: this.filetype,
        theme_type: this.style.match(/custom/) ? 'custom' : this.style,
        theme_id: this.active_theme_id,
      }
    },
    embed_snippet() {
      const params = Object.entries(this.download_options)
        .map(([k, v]) => `${k}=${v}`)
        .join('&')
      const srcUrl =
        location.protocol +
        '//' +
        location.host +
        '/render/testimonial/' +
        this.content_asset.identifier +
        '?' +
        params
      return `<iframe src='${srcUrl}' frameBorder='0'></iframe>`
    },
    style_options() {
      const default_options = [
        {
          value: 'basic',
          text: 'Basic',
        },
        {
          // "styled" was the legacy name for this theme
          value: 'styled',
          text: 'Accent',
        },
        {
          value: 'branded_primary',
          text: 'Branded - Primary',
        },
        {
          value: 'branded_secondary',
          text: 'Branded - Secondary',
        },
        {
          value: 'branded_primary_and_secondary',
          text: 'Branded - Primary and Secondary',
        },
      ]

      // hide custom themes unless activated for the account
      if (!this.account.custom_themes_active) {
        return default_options
      }

      if (!this.testimonial_themes) {
        return default_options
      }

      const custom_options = Object.values(this.testimonial_themes).map((template) => {
        return {
          text: template.name,
          value: `custom:${template.id}`,
        }
      })
      return default_options.concat(custom_options)
    },
    eventData() {
      return {
        ...assetEventData(this.content_asset),
        size: this.size,
        filetype: this.filetype,
        style: this.style,
        theme_id: this.active_theme_id,
      }
    },
    showSlideOptions() {
      return (
        this.account?.flipper_features.includes('slides') && this.account?.slide_templates_count > 0
      )
    },
    showSlideNudge() {
      // Slide feature is available but the account has no templates
      return (
        this.account?.flipper_features.includes('slides') &&
        (this.account?.slide_templates_count ?? 0) === 0
      )
    },
  },
  async beforeMount() {
    this.account = this.content_asset.account
    if (this.custom_themes === undefined) {
      this.testimonial_themes = await getTestimonialThemesMap(this.account.id)
    }
    this.fetching_themes = false
  },
  methods: {
    liClick() {
      this.size = null
      this.filetype = 'png'
      this.style = 'styled'
    },
    igClick() {
      this.size = 'square'
      this.filetype = 'png'
      this.style = 'styled'
    },
    twClick() {
      this.size = 'wide'
      this.filetype = 'png'
      this.style = 'styled'
    },
    fbClick() {
      this.size = null
      this.filetype = 'png'
      this.style = 'styled'
    },
    ppClick() {
      this.size = null
      this.filetype = 'pptx'
    },
    attemptPopUp(url) {
      const new_window = window.open(url, '_blank')
      try {
        new_window.focus()
      } catch (e) {
        // Trying to `window.open` a PDF attachment file seems to trigger pop-up blockers on Chrome
        alert(
          'It looks like a pop-up blocker prevented the download window from opening. Please allow them for this site.',
        )
      }
    },
    trackCopy() {
      ahoy.track('asset share link copied', this.eventData)
      amplitudeTrack('asset share link copied', this.eventData)
    },
    onDownload() {
      ahoy.track('asset downloaded', this.eventData)

      amplitudeTrack('asset downloaded', {
        asset_id: this.content_asset.identifier,
        asset_type: this.content_asset.type,
        size: this.size,
        filetype: this.filetype,
        style: this.style,
        theme_id: this.active_theme_id,
        location: this.$route.path,
        account_id: this.content_asset.account.id,
        account_name: this.content_asset.account.name,
      })

      pendoTrack('download_asset', {
        asset_id: this.content_asset.identifier,
        asset_type: 'RenderableTestimonial',
        size: this.size,
        filetype: this.filetype,
        style: this.style,
        theme_id: this.active_theme_id,
        location: this.$route.path,
      })

      this.download_in_progress = true

      if (this.download_options.filetype === 'pptx') {
        this.attemptPopUp(
          `/api/renderable_testimonials/${this.content_asset.id}/download_pptx.json`,
        )
        this.download_in_progress = false
      } else {
        renderAsset(this.content_asset.id, this.download_options)
          .then((r) => {
            return r.data.download_url
          })
          .then((url) => this.attemptPopUp(url))
          .catch(() => alert('Darn! Something went wrong. Please try again.'))
          .finally(() => (this.download_in_progress = false))
      }
    },
  },
}
</script>
<style lang="sass">
// This @import is a hack...
// This component appears in the app AND the site pack. The latter
// does not include all Bootstrap CSS, so the child components used
// above would not display correctly. But we can't safely add that
// css globally to the entire pack because Bootstrap styles conflict
// with custom styles that currently apply to the site pages. The
// `scoped` attribute also prevents the styles (here) from applying
// to the nested components.
.modal_container--testimonial
  @import "../../../../node_modules/bootstrap/dist/css/bootstrap"

// These next styles are redundant in the app pack context, but they
// are necessary for consistency in the site pack context. These can
// probably be removed if/when the conflicts between app and site packs
// are reconciled.
.modal_container--testimonial
  font-size: 16px
  // This is a better version of the
  // legacy .modal_footer that uses absolute position
  .modal_footer_new
    padding: 0 20px 25px 20px
  .share-option__title
    margin-bottom: 0
  .share-option .custom-control-label::before
    background-color: hsl(200deg, 16%, 90%)
    top: 4px
    left: -24px
    width: 20px
    height: 20px
  .share-option .custom-control-label::after
    top: 2px
    left: -26px
    height: 24px
    width: 24px
  .share-option .custom-control-input:checked ~ .custom-control-label::before
    border-color: hsl(270deg, 100%, 52%)
    background-color: hsl(270deg, 100%, 52%)
  .preset-options .btn-secondary
    background-color: #F2F6F7
    font-size: 13px
    border-radius: 10px
    border: none
  .download button
    background-color: hsl(270deg, 100%, 52%)
    line-height: 1
    font-size: 15px
    border-radius: 10px
    padding: 11px 24px
    border: 2px solid transparent
    &:hover, &:active
      background-color: hsl(270deg, 100%, 37%)!important
      border-color: transparent!important
    &[disabled]
      background-color: hsl(270deg, 100%, 52%)
      border-color: transparent
  .preview .content_asset
    border: 1px solid hsl(200deg, 24%, 92%)
    border-radius: 16px

// Everything below are the original styles for this component
.modal_container--testimonial
  &.modal_container .modal_header
    margin-bottom: 0
  .share-options
    display: grid
    grid-column-gap: 1em
  .share-option
    margin-bottom: 0
    input[disabled] + label
      text-decoration: line-through
  .preset-options
    display: inline-block
    .btn
      display: inline-block
      margin-left: 1em
      padding: 8px
      img
        display: inline-block
        width: 2em
        height: 2em
  .preview
    pointer-events: none
    overflow: hidden
    // this is a safeguard to keep borked themes from breaking surrounding layouts
    // 191 designs overflow the container
    .testimonial_social_191.content_asset.crimp
      overflow: hidden
    .asset_presenter .content_asset
      margin-left: auto
      margin-right: auto
  &.freestanding
    .modal_header, .modal_body
      padding-left: 0
      padding-right: 0
</style>
